<template>
  <Drawer title="广告管理" v-model="isShow" width="600" scrollable draggable>
    <!-- <Divider orientation="left">广告管理</Divider> -->
    <Card style="margin-bottom: 8px">
      <Row class="text-cell">
        <Col span="6" style="position: relative">
          <Avatar
            style="width: 100px; height: 100px"
            @click="handleViewImg($setImagePrefix(obj.ImgPath))"
            :src="$setImagePrefix(obj.ImgPath)"
          ></Avatar>
        </Col>
      </Row>
    </Card>
    <Row class="text-cell">
      <Col class="title" span="3">广告标题:</Col>
      <Col class="value" span="9">{{ obj.Title }}</Col>
      <Col class="title" span="3">广告位置:</Col>
      <Col class="value" span="9">{{ obj.PositionCN }}</Col>
    </Row>
    <Row v-if="obj.PositionCN === '启动弹出广告'" class="text-cell">
      <Col class="title" span="3">广告格式:</Col>
      <Col class="value" span="9">{{ obj.Type === 0 ? "图片" : "视频" }}</Col>
      <Col class="title" span="3">视频地址:</Col>
      <Col class="value" span="9">{{ obj.VideoPath }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">排序参数:</Col>
      <Col class="value" span="9">{{ obj.Index }}</Col>
      <Col class="title" span="3">状态:</Col>
      <Col class="value" span="9">{{ obj.StatusCN }}</Col>
      <!-- <Col class="title"
           span="3">弹出频率:</Col>
      <Col class="value"
           span="9">{{ obj.Frequency === 1 ? '每日广告' : obj.Frequency === 2 ? '唤醒广告' : obj.Frequency === 3 ? '登录广告' : '' }}</Col> -->
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">链接地址:</Col>
      <Col class="value" span="9">{{ obj.Url }}</Col>
      <Col class="title" span="3">开始时间:</Col>
      <Col class="value" span="9">{{ obj.BeginDate }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">结束时间:</Col>
      <Col class="value" span="9">{{ obj.EndDate }}</Col>
      <Col class="title" span="3">是否预告:</Col>
      <Col class="value" span="9">{{ obj.IsPreview ? "是" : "否" }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">提前预告时间(分钟):</Col>
      <Col class="value" span="9">{{ obj.PreviewMin }}</Col>
      <Col class="title" span="3">创建人:</Col>
      <Col class="value" span="9">{{ obj.CreateUserName }}</Col>
    </Row>
    <Row class="text-cell">
      <Col class="title" span="3">创建时间:</Col>
      <Col class="value" span="9">{{ obj.CreateDate }}</Col>
      <Col class="title" span="3">广告位:</Col>
      <Col class="value" span="9">{{ obj.PositionCN }}</Col>
    </Row>
    <Modal
      title="查看大图"
      class-name="i-modal"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <img :src="ViewImg" v-if="isShowViewImage" style="width: 100%" />
    </Modal>
  </Drawer>
</template>

<script>
export default {
  name: "advertDetail-drawer",
  data() {
    return {
      isShowViewImage: false,
      isShow: false,
      obj: {},
      ViewImg: "",
    };
  },
  methods: {
    handleViewImg(src) {
      this.ViewImg = src;
      this.isShowViewImage = true;
    },
  },
};
</script>

<style lang="scss"></style>
